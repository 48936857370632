import { selectAssignment } from '@redux/experiments/selectors/index';
import type { PricingOnListingsAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const newStartingPriceAssignmentSelector = (state: State) => {
	if (state.category.code !== 'WPH') {
		return null;
	}

	return selectAssignment(state, [
		'newStartingPrice',
	]) as PricingOnListingsAssignment;
};

export { newStartingPriceAssignmentSelector };
