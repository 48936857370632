import { selectAssignment } from '@redux/experiments/selectors/index';
import type { QuickResponderAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const quickResponderAssignmentSelector = (state: State) => {
	return selectAssignment(state, [
		'quickResponder',
	]) as QuickResponderAssignment;
};

export { quickResponderAssignmentSelector };
