import { type ClassesRecord, compose } from '@xo-union/react-css-modules';
import {
	FullscreenModal,
	type ModalEvent,
} from '@xo-union/tk-component-modals';
import type { FullscreenModalClasses } from '@xo-union/tk-component-modals';
import React, { type FC } from 'react';
import SearchByLocation from '../../../../../components/shared/Search/Mobile/containers/ChangeSearchModal/SearchByLocation';
import Styles from './styles.scss';

const modalClasses = compose<
	FullscreenModalClasses,
	ClassesRecord<FullscreenModalClasses>
>({
	container: Styles.semanticMobileContainer,
	content: Styles.semanticContent,
	header: Styles.semanticModalHeader,
	'tk-logo': Styles.tkLogo,
});

interface SemanticMobileModalProps {
	handleModalOnClose: (e: ModalEvent) => void;
}

export const SemanticMobileModal: FC<SemanticMobileModalProps> = (props) => {
	const { handleModalOnClose } = props;

	return (
		<FullscreenModal classes={modalClasses} onClose={handleModalOnClose}>
			<SearchByLocation title="Category & Location" />
		</FullscreenModal>
	);
};
