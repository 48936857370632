import { selectAssignment } from '@redux/experiments/selectors/index';
import type { BetterImageAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const betterImageAssignmentSelector = (state: State) => {
	return state.viewport.isMobile && state.page.pageType === 'city'
		? (selectAssignment(state, ['betterImage']) as BetterImageAssignment)
		: null;
};

export { betterImageAssignmentSelector };
