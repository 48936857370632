import type { ModalEvent } from '@xo-union/tk-component-modals';
import React, { type FC, useState } from 'react';
import { SemanticCategory } from './SemanticCategory';
import { SemanticLocation } from './SemanticLocation';
import { SemanticMobileModal } from './SemanticMobileModal';
import Styles from './styles.scss';

export const SemanticControls: FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleModalOnClose = (e: ModalEvent) => {
		if (e._reactName === 'onClick') {
			setIsModalOpen(false);
		}
	};

	return (
		<>
			<div className={Styles.controls}>
				<SemanticCategory setIsModalOpen={setIsModalOpen} />
				<div className={Styles.spacer}>in</div>
				<SemanticLocation setIsModalOpen={setIsModalOpen} />
			</div>
			{isModalOpen && (
				<SemanticMobileModal handleModalOnClose={handleModalOnClose} />
			)}
		</>
	);
};
