import { useAppSelector } from '@redux/hooks';
import Icon from '@xo-union/tk-component-icons';
import React, { useState } from 'react';
import { ChangeLocationModal } from '../../../../Category/Recommendations/ChangeLocationModal';
import { useTrackCatGeo } from '../hooks/use-track-cat-geo';
import Styles from './styles.scss';

interface Props {
	setIsModalOpen: (value: boolean) => void;
}

export const SemanticLocation = ({ setIsModalOpen }: Props) => {
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const locationString = useAppSelector(
		(state) => `${state.location.city}, ${state.location.stateCode}`,
	);
	const [isDesktopModalOpen, setIsDesktopModalOpen] = useState(false);
	const trackCatGeo = useTrackCatGeo();

	const handleClick = () => {
		trackCatGeo('open_geography_menu');
		if (isMobile) {
			return setIsModalOpen(true);
		}
		return setIsDesktopModalOpen(true);
	};

	return (
		<>
			<button type="button" onClick={handleClick}>
				<div className={Styles.semanticLocation}>
					<Icon name="pin" size="sm" />
					<div className={Styles.semanticLocationText}>{locationString}</div>
				</div>
			</button>
			<ChangeLocationModal
				headerText="Location"
				isModalOpen={isDesktopModalOpen}
				closeCallback={() => setIsDesktopModalOpen(false)}
			/>
		</>
	);
};
