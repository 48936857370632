import SelectOptionStyles from '@components/shared/Search/Category/styles.scss';
import type { Categories } from '@typings/redux';
import type { IconName } from '@xo-union/dist-iconography';
import Icon from '@xo-union/tk-component-icons';
import React, { type FC } from 'react';
import type { CategoryCode } from 'types/Category';
import { useTrackCatGeo } from '../hooks/use-track-cat-geo';
import Styles from './styles.scss';

export interface SemanticDropdownProps {
	categories: Categories;
	handleKeyDown: (event: React.KeyboardEvent, catCode: CategoryCode) => void;
	onDropdownSelection: (catCode: string, categories: Categories) => void;
	optionRefs: React.RefObject<HTMLButtonElement>[];
	selectedCategory: Category.Category;
}

export const SemanticDropdown: FC<SemanticDropdownProps> = (props) => {
	const {
		categories,
		handleKeyDown,
		onDropdownSelection,
		optionRefs,
		selectedCategory,
	} = props;
	const trackCatGeo = useTrackCatGeo();

	const handleClick = (categoryCode: CategoryCode) => {
		trackCatGeo('select category', { categoryCode });
		onDropdownSelection(categoryCode, categories);
	};

	return (
		<div aria-label="listbox" className={Styles.dropdown}>
			{categories.map((category, index) => (
				<button
					aria-pressed="false"
					className={
						selectedCategory.name === category.name
							? `${Styles.option} ${Styles.selected}`
							: Styles.option
					}
					data-option-index={index}
					key={category.code}
					onClick={() => handleClick(category.code)}
					onKeyDown={(e) => handleKeyDown(e, category.code)}
					ref={optionRefs[index]}
					type="button"
					tabIndex={0}
				>
					<div className={SelectOptionStyles.optionIcon}>
						<Icon
							name={`category-${category.code.toLowerCase()}` as IconName}
							size="md"
						/>
					</div>
					<div className={SelectOptionStyles.optionLabel}>{category.name}</div>
				</button>
			))}
		</div>
	);
};
