import { Body1, H4 } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './styles.scss';

export const SemanticSearchErrorResults = () => {
	const message = 'Something went wrong. Please try again later.';
	return (
		<div className={Styles.zeroResults}>
			<H4>No Results Found</H4>
			<Body1>{message}</Body1>
		</div>
	);
};
