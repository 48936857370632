export const test_aStateCodes = [
	'tx',
	'pa',
	'ga',
	'nj',
	'az',
	'in',
	'wi',
	'sc',
	'ky',
	'ct',
	'nv',
	'ms',
	'id',
	'nh',
	'ri',
	'nd',
	'wy',
	'ok',
	'ia',
];

export const test_bStateCodes = [
	'fl',
	'il',
	'nc',
	'va',
	'tn',
	'mo',
	'co',
	'al',
	'or',
	'ut',
	'ar',
	'nm',
	'wv',
	'me',
	'de',
	'ak',
	'wa',
	'ks',
	'ne',
	'sd',
];

export default function catalogTitleExperiment(
	location: Union.Location | null,
	categoryCode: Category.CategoryCode,
): string {
	const isVenue = categoryCode === 'REC';

	if (!isVenue) {
		return '';
	}

	const city = location?.city || '';
	const stateCode = location?.stateCode || '';

	const test_a = {
		title: `Find Your Perfect ${city} Wedding Venue`,
		locations: test_aStateCodes,
	};

	const test_b = {
		title: `Browse ${city}'s Top Wedding Venues`,
		locations: test_bStateCodes,
	};

	let title = '';

	if (test_a.locations.includes(String(stateCode).toLowerCase())) {
		title = test_a.title;
	} else if (test_b.locations.includes(String(stateCode).toLowerCase())) {
		title = test_b.title;
	}

	return title;
}
