import { useAppSelector } from '@redux/hooks';

type UseLocationValues =
	| { market_code: string; city?: never; state?: never }
	| { market_code?: never; city: string; state: string };

export const useLocationValues = (): UseLocationValues => {
	const marketCode = useAppSelector((state) => state.settings.marketCode);
	const stateCode = useAppSelector((state) => state.location.stateCode);
	const city = useAppSelector((state) => state.location.city);

	if (marketCode) {
		return {
			market_code: marketCode,
		};
	}

	if (city && stateCode) {
		return {
			city,
			state: stateCode,
		};
	}

	return {
		city: '',
		state: '',
	};
};
