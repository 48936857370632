import { Logger } from '@utils/logger/logger';
import { SnackbarRef } from '@xo-union/tk-component-alerts';
import React, { RefObject } from 'react';
import ShowAlert from '../../components/showAlert';

interface ShowAlertParams {
	alertRef: RefObject<null | SnackbarRef>;
	membership: Redux.Membership;
}

const showAlertMessage =
	<T extends unknown>(params: ShowAlertParams) =>
	(vendor: Vendor.Raw, isFailure: boolean, response?: T) => {
		const { alertRef, membership } = params;
		if (alertRef?.current) {
			alertRef.current.showAndDismiss({
				data: <ShowAlert vendor={vendor} isFailure={isFailure} />,
				dismissTimeout: 5000,
			});
		}

		if (isFailure) {
			Logger.error({
				log_message: 'Failed to save vendor',
				errorToLog: new Error('Failed to save vendor'),
				optionalParams: {
					component: 'WeddingsContextProvider',
					membership,
					response,
					vendorId: vendor.id,
				},
				newRelic: true,
			});
		}
	};

export default showAlertMessage;
