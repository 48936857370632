import { selectAssignment } from '@redux/experiments/selectors/index';
import type { RecentlyViewedAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const recentlyViewedAssignmentSelector = (state: State) => {
	return selectAssignment(state, [
		'recentlyViewed',
	]) as RecentlyViewedAssignment;
};

export { recentlyViewedAssignmentSelector };
