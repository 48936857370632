import { semanticSearchV2Selector } from '@redux/experiments/selectors/semantic-search-v2';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import React, {
	type Dispatch,
	type FC,
	type SetStateAction,
	useCallback,
	useMemo,
} from 'react';
import { semanticSearchTypeaheadAtom } from '../atoms';
import { SemanticSuggestionList } from './SemanticSuggestionList';

export interface SemanticSuggestionsProps {
	isSemanticSuggestionsOpen: boolean;
	setIsSemanticSuggestionsOpen: Dispatch<SetStateAction<boolean>>;
}

const TEMP_STYLES: React.CSSProperties = {
	backgroundColor: 'white',
	border: '2px solid black',
	padding: '2rem',
	position: 'absolute',
	top: '100px',
};

export const SemanticSuggestions: FC<SemanticSuggestionsProps> = (props) => {
	const { isSemanticSuggestionsOpen, setIsSemanticSuggestionsOpen } = props;
	const semanticSearchV2Assignment = useAppSelector((state) =>
		semanticSearchV2Selector(state),
	);
	const semanticSearchTypeahead = useAtomValue(semanticSearchTypeaheadAtom);
	const isInSemanticSearchV2 =
		semanticSearchV2Assignment === 'semantic-search-v2';

	const searchSuggestions = useMemo(() => {
		return semanticSearchTypeahead.semanticSearchResults.map((s) => {
			return {
				key: s.facet ?? s.term,
				label: s.facet ?? s.term,
			};
		});
	}, [semanticSearchTypeahead.semanticSearchResults]);

	const storefrontSuggestions = useMemo(() => {
		return semanticSearchTypeahead.storefronts.map((s) => {
			return {
				key: s.name,
				label: s.name,
			};
		});
	}, [semanticSearchTypeahead.storefronts]);

	const handleClick = useCallback(() => {
		setIsSemanticSuggestionsOpen(false);
	}, [setIsSemanticSuggestionsOpen]);

	if (!isInSemanticSearchV2 || !isSemanticSuggestionsOpen) {
		return null;
	}

	return (
		<div style={TEMP_STYLES}>
			<SemanticSuggestionList
				suggestions={searchSuggestions}
				handleClick={handleClick}
			/>
			<hr />
			<SemanticSuggestionList
				suggestions={storefrontSuggestions}
				handleClick={handleClick}
			/>
		</div>
	);
};
