import { selectAssignment } from '@redux/experiments/selectors/index';
import type { SemanticSearchV2Assignment } from '@typings/experiments';
import type { State } from 'types/redux';

const semanticSearchV2Selector = (state: State) => {
	return selectAssignment(state, [
		'semanticSearchV2',
	]) as SemanticSearchV2Assignment;
};

export { semanticSearchV2Selector };
