import {
	selectIsBOWFilterApplied,
	selectShouldHideSpotlight,
} from '@redux/filters/selectors';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { SemanticTopBar } from '../SemanticSearch/components/SemanticTopBar';

interface TopBarProps {
	categoryCode: Category.CategoryCode;
	isBOW: boolean;
	isMobile: boolean;
	location: Redux.Location;
	marketCode: string;
	shouldHideSpotlight: boolean;
}

const TopBar: FC<TopBarProps> = (props) => {
	const { categoryCode, isBOW, isMobile, marketCode, shouldHideSpotlight } =
		props;

	const shouldShowSpotlight = !shouldHideSpotlight && !isMobile;

	return (
		<SemanticTopBar
			categoryCode={categoryCode}
			isBOW={isBOW}
			location={props.location}
			marketCode={marketCode}
			shouldShowSpotlight={shouldShowSpotlight}
		/>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	categoryCode: state.category.code,
	isBOW: selectIsBOWFilterApplied(state),
	isMobile: state.viewport.isMobile,
	location: state.location,
	marketCode: state.settings.marketCode,
	shouldHideSpotlight: selectShouldHideSpotlight(state),
});

export default connect(mapStateToProps)(TopBar);
