import { selectAssignment } from '@redux/experiments/selectors/index';
import type { REC_VID_FLO_startingPriceAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const newExpandedStartingPriceAssignmentSelector = (state: State) => {
	const categories = new Set(['REC', 'VID', 'FLO']);
	if (!categories.has(state.category.code)) {
		return null;
	}

	return selectAssignment(state, [
		'REC_VID_FLO_startingPrice',
	]) as REC_VID_FLO_startingPriceAssignment;
};

export { newExpandedStartingPriceAssignmentSelector };
