import { selectAssignment } from '@redux/experiments/selectors/index';
import type { ExpectedValueSortAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const expectedValueSortSelector = (state: State) => {
	return selectAssignment(state, [
		'expectedValue',
	]) as ExpectedValueSortAssignment;
};

export { expectedValueSortSelector };
