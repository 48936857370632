import { semanticSearch, semanticSearchApiV2 } from '@api/semanticSearch';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { semanticSearchV2Selector } from '@redux/experiments/selectors/semantic-search-v2';
import { useAppSelector } from '@redux/hooks';
import { Logger } from '@utils/logger/logger';
import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { Field, Form, type PseudoEvent } from '@xo-union/tk-component-fields';
import { IconButton } from '@xo-union/tk-component-icons';
import { useAtomValue, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import React, { type FC, useCallback, useMemo, useRef, useState } from 'react';
import AnalyticsEvents from '../../../../../../constants/analytics';
import {
	isSemanticSearchCalledAtom,
	resetSemanticSearchResponseAtom,
	semanticSearchResponseAtom,
	semanticSearchTypeaheadAtom,
	updateIsFetchingAtom,
	updateIsSemanticSearchCalled,
} from '../atoms';
import { useLocationValues } from '../hooks/use-location-values';
import { hydrateVendors } from '../utils';
import { SemanticControls } from './SemanticControls';
import Styles from './SemanticSearch.styles.scss';
import { SemanticSuggestions } from './SemanticSuggestions';

const fieldClasses = compose({
	container: Styles.fieldContainer,
	input: Styles.fieldInput,
});

export interface SemanticSearchProps {
	fieldValue: string;
	setFieldValue: (value: string) => void;
}

export const SemanticSearch: FC<SemanticSearchProps> = (props) => {
	const { fieldValue, setFieldValue } = props;
	const [isSemanticSuggestionsOpen, setIsSemanticSuggestionsOpen] =
		useState(false);
	const categoryCode = useAppSelector((state) => state.category.code);
	const stateCode = useAppSelector((state) => state.location.stateCode);
	const city = useAppSelector((state) => state.location.city);
	const semanticSearchV2Assignment = useAppSelector((state) =>
		semanticSearchV2Selector(state),
	);
	const locationValues = useLocationValues();
	const setSemanticSearchResponse = useSetAtom(semanticSearchResponseAtom);
	const setSemanticSearchTypeahead = useSetAtom(semanticSearchTypeaheadAtom);
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const setIsFetching = useSetAtom(updateIsFetchingAtom);
	const setIsSemanticSearchCalled = useSetAtom(updateIsSemanticSearchCalled);
	const resetSemanticSearchResponse = useSetAtom(
		resetSemanticSearchResponseAtom,
	);

	const closeButtonRef = useRef<HTMLButtonElement>(null);

	const { track } = useAnalyticsContext();
	const trackSearchButtonClicked = useCallback(() => {
		const properties = {
			product: 'marketplace',
			searchTerm: fieldValue,
			sourcePage: isSemanticSearchCalled
				? AnalyticsEvents.SEMANTIC_RESULTS
				: 'category results',
			sourceContent: 'free_search_box',
			action: 'search_completed',
			vendorCategoryCode: categoryCode,
			location: `${city}, ${stateCode}`,
		};

		track({
			event: AnalyticsEvents.VENDOR_SEARCH_INTERACTION,
			properties,
		});
	}, [
		categoryCode,
		fieldValue,
		isSemanticSearchCalled,
		track,
		city,
		stateCode,
	]);

	const onInputChange = useMemo(
		() =>
			debounce((searchString: string) => {
				track({
					event: AnalyticsEvents.VENDOR_SEARCH_INTERACTION,
					properties: {
						searchString,
						action: 'typing',
						sourceContent: 'free_search_box',
						sourcePage: isSemanticSearchCalled
							? AnalyticsEvents.SEMANTIC_RESULTS
							: AnalyticsEvents.CATEGORY_RESULTS,
						vendorCategoryCode: categoryCode,
						location: `${city}, ${stateCode}`,
					},
				});
			}, 1000),
		[categoryCode, track, isSemanticSearchCalled, city, stateCode],
	);

	const handleSearchTextChange = async (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const value = e.target.value;
		setFieldValue(value);
		onInputChange(value);

		if (semanticSearchV2Assignment === 'semantic-search-v2') {
			const res = await semanticSearchApiV2(value);
			setIsSemanticSuggestionsOpen(e.target.value.length > 0);
			setSemanticSearchTypeahead(res);
		}
	};

	const handleFieldClick = () => {
		if (
			document.activeElement === closeButtonRef?.current &&
			fieldValue.length > 0
		) {
			setFieldValue('');
			resetSemanticSearchResponse();
			setIsSemanticSuggestionsOpen(false);
		}
	};

	const handleSearch = async () => {
		trackSearchButtonClicked();
		setIsFetching(true);

		try {
			if (semanticSearchV2Assignment === 'semantic-search-v2') {
				setIsSemanticSearchCalled(true);
				const res = await semanticSearchApiV2(fieldValue);
				setSemanticSearchTypeahead(res);
				setIsFetching(false);
				return;
			}
			const semanticSearchResponse = await semanticSearch({
				style_description: fieldValue,
				vendor_category: categoryCode,
				included_vendor_tiers: 'paid-only',
				exclude_storefronts_without_image: true,
				exclude_unclaimed_venues: true,
				limit: 80,
				include_venue_desc: false,
				...locationValues,
			});
			const ids = semanticSearchResponse?.response?.storefronts?.map(
				(s) => s.id,
			);
			const vendors = await hydrateVendors(ids);
			setSemanticSearchResponse({
				isFetching: false,
				isSemanticSearchCalled: true,
				response_id: semanticSearchResponse?.response?.response_id,
				searchFieldValue: fieldValue,
				storefronts: vendors,
				storefrontsCount: vendors.length,
				isError: false,
			});
		} catch (error) {
			setSemanticSearchResponse({
				isFetching: false,
				isSemanticSearchCalled: true,
				response_id: '',
				searchFieldValue: fieldValue,
				storefronts: [],
				storefrontsCount: 0,
				isError: true,
			});
			Logger.error({
				log_message: 'Error performing semantic search',
				newRelic: true,
				errorToLog: error,
			});
		}
	};

	const handleSubmit = (pseudoEvent: PseudoEvent) => {
		if (!pseudoEvent.valid) {
			return;
		}
		handleSearch();
	};

	return (
		<div className={Styles.semanticSearchWrapper}>
			<SemanticControls />

			<Form className={Styles.semanticSearchForm} onSubmit={handleSubmit}>
				<Field
					classes={fieldClasses}
					label={
						semanticSearchV2Assignment === 'semantic-search-v2'
							? 'Search vendors, styles, details'
							: 'Search'
					}
					name="search..."
					onChange={handleSearchTextChange}
					validations={{ required: true }}
					value={fieldValue}
					subTextOnInvalid=""
				/>
				<IconButton<HTMLButtonElement>
					aria-label="clear search field"
					className={
						fieldValue.length > 0
							? Styles.clearButton
							: Styles.clearButtonHidden
					}
					name="close_circle"
					onClick={handleFieldClick}
					ref={closeButtonRef}
					size="md"
				/>
				<Button
					className={Styles.semanticSearchButton}
					color="primary"
					iconName="search"
					size="lg"
					type="submit"
					aria-label="Submit search"
				/>
			</Form>
			<SemanticSuggestions
				isSemanticSuggestionsOpen={isSemanticSuggestionsOpen}
				setIsSemanticSuggestionsOpen={setIsSemanticSuggestionsOpen}
			/>
		</div>
	);
};
