import React, { type FC } from 'react';

interface SemanticSuggestion {
	key?: string;
	label?: string;
}

export interface SemanticSuggestionListProps {
	suggestions: SemanticSuggestion[];
	handleClick: () => void;
}

export const SemanticSuggestionList: FC<SemanticSuggestionListProps> = (
	props,
) => {
	const { suggestions, handleClick } = props;
	return (
		<ul>
			{suggestions.map((s) => (
				<li key={s.key}>
					<button type="button" onClick={handleClick}>
						{s.label}
					</button>
				</li>
			))}
		</ul>
	);
};
